import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useSearchParams } from "react-router-dom";
import StripeCheckoutPage from "./StripeCheckout";

const STRIPE = process.env.REACT_APP_STRIPE_API_URL;

const stripePromise = loadStripe(STRIPE);

const Payment = () => {
  const [searchParams] = useSearchParams();

  const secret = searchParams.get("id");

  if (!secret) {
    return <div className="container">Error: Missing payment information.</div>;
  }

  const appearance = {
    theme: "stripe",
    // paymentMethodOrder: ["card", "google_pay", "apple_pay"],
  };
  const options = {
    appearance,
    clientSecret: secret,
  };

  return (
    <div className="max-w-3xl mx-auto my-20">
      <Elements stripe={stripePromise} options={options}>
        <StripeCheckoutPage secret={secret} amount={200} />
      </Elements>
    </div>
  );
};

export default Payment;
