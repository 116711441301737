import { Link } from "react-router-dom";


export default function Component() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
      <header className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold text-gray-900 flex items-center">
            <svg className="h-10 w-10 text-blue-600 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Shop and Earn
          </h1>
          <p className="mt-2 text-lg text-gray-600">Get rewarded for your purchases</p>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-8">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">How It Works</h2>
              <p className="text-xl text-gray-600 mb-8 leading-relaxed">
                Our Shop and Earn program is designed to reward you for every purchase you make. 
                It's simple, straightforward, and packed with benefits that will enhance your 
                shopping experience and save you money.
              </p>

              <div className="grid md:grid-cols-2 gap-8 mb-12">
                <div className="bg-blue-50 rounded-lg p-6 shadow-inner">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    <svg className="h-6 w-6 text-blue-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Earn Points
                  </h3>
                  <p className="text-gray-700">
                    Accumulate points on every purchase you make through our platform. The more you shop, the more you earn!
                  </p>
                </div>
                <div className="bg-green-50 rounded-lg p-6 shadow-inner">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    <svg className="h-6 w-6 text-green-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Flexible Redemption
                  </h3>
                  <p className="text-gray-700">
                    Use your points for discounts, special offers, or even cash back. The choice is yours!
                  </p>
                </div>
                <div className="bg-purple-50 rounded-lg p-6 shadow-inner">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    <svg className="h-6 w-6 text-purple-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    No Expiry
                  </h3>
                  <p className="text-gray-700">
                    Your earned points never expire, giving you the freedom to save up for bigger rewards.
                  </p>
                </div>
                <div className="bg-yellow-50 rounded-lg p-6 shadow-inner">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                    <svg className="h-6 w-6 text-yellow-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                    </svg>
                    Exclusive Access
                  </h3>
                  <p className="text-gray-700">
                    Gain early access to sales and special member-only events. Be the first to grab amazing deals!
                  </p>
                </div>
              </div>

              <div className="bg-gray-50 p-8 rounded-lg shadow-inner mb-12">
                <h3 className="text-2xl font-bold text-gray-900 mb-6">Getting Started is Easy</h3>
                <ol className="list-decimal list-inside text-gray-700 space-y-4">
                  <li className="text-lg">Create an account on our platform</li>
                  <li className="text-lg">Start shopping from our wide range of products</li>
                  <li className="text-lg">Earn points with every purchase</li>
                  <li className="text-lg">Redeem your points for rewards or discounts</li>
                </ol>
              </div>

              <div className="text-center">
                <Link
                  to={'/signin'}
                  className="inline-block bg-ourPrimary text-white text-lg font-semibold px-8 py-4 rounded-full hover:bg-ourPrimary-foreground transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
                >
                  Join Now and Start Earning
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}