import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { Link } from "react-router-dom";
import { MoveRight } from "lucide-react";
import ProductSlider from "../../components/ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import { get_topsellingproducts } from "../../store/reducers/HomeReducer";
import SkeletonCarousel from "../../components/SkeletonCarousel";

const TopSellingProducts = () => {
  const dispatch = useDispatch();
  const { top_selling_products } = useSelector((state) => state.home);
  const [loading, setLoading] = useState(true); // Local loading state

  useEffect(() => {
    const fetchTopSellingProducts = async () => {
      await dispatch(get_topsellingproducts({ page: 1, size: 15 }));
      setLoading(false); // Set loading to false after fetching products
    };

    fetchTopSellingProducts();
  }, [dispatch]);

  // Do not render anything if products are empty and loading is complete
  if (!loading && (!top_selling_products || top_selling_products.length === 0)) {
    return null;
  }

  return (
    <div className="container mx-auto p-3">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-[20px] md:text-2xl font-semibold">
          Top Selling Products
        </h2>
        <Link
          to={`/products?specialCategory=${encodeURIComponent("Top Selling")}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>
      {loading ? (
        // Show loading skeleton when loading
        <SkeletonCarousel full={true} />
      ) : top_selling_products && top_selling_products.length > 0 ? (
        <ProductSlider products={top_selling_products} />
      ) : (
        <h4 className="my-10 text-lg font-bold">No Products To Show!</h4>
      )}
    </div>
  );
};

export default TopSellingProducts;
