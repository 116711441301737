import React, { useEffect, useState, useRef } from "react";
import { FaRegUserCircle, FaShoppingBag } from "react-icons/fa";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { MdOutlineLoop } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_cart_quotation,
  removeCartItems,
} from "../../../store/reducers/CartReducer";
import { get_userinfo } from "../../../store/reducers/HomeReducer";

const API_URL = process.env.REACT_APP_API_URL;

const NavMenu = () => {
  const [open, setOpen] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);
  const [userInfo, setUserInfo] = useState();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading: loader,
    successMessage,
    errorMessage,
    user_info,
  } = useSelector((state) => state.home);

  const links = [
    {
      title: "Profile",
      url: "/account/profile",
      icon: <BsFillGrid1X2Fill />,
    },
    {
      title: "My Orders",
      url: "/account/myorders",
      icon: <MdOutlineLoop />,
    },
    {
      title: "My Wishlists",
      url: "/account/mywishlists",
      icon: <FaRegHeart />,
    },
    {
      title: "My Cart",
      url: "/account/mycart",
      icon: <FaShoppingBag />,
    },
    // {
    //   title: "Setting",
    //   url: "/account/setting",
    //   icon: <FaGear />,
    // },
  ];

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    setUserInfo(info[0]);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [pathname, scrollValue]);

  const getScrollValue = () => {
    const value = window.scrollY;
    setScrollValue(value);
  };

  useEffect(() => {
    window.addEventListener("scroll", getScrollValue);
    return () => window.removeEventListener("scroll", getScrollValue);
  }, []);

  //functions
  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userSecret");
    localStorage.removeItem('cart_length')
    navigate("/");
    dispatch(removeCartItems());
    dispatch(get_cart_quotation());
  };

  useEffect(() => {
    dispatch(get_userinfo());
  }, [dispatch]);

  return (
    <div className="relative w-full">
      <button
        onClick={() => setOpen(!open)}
        ref={buttonRef}
        className="flex items-center justify-center w-full gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer"
      >
        <div>
          {user_info?.user_image ? (
            <div className="size-7 flex items-center justify-center rounded-full overflow-hidden border">
              <img
                src={`${API_URL}${user_info?.user_image}`}
                alt=""
                className="h-full"
              />
            </div>
          ) : (
            <FaRegUserCircle className="text-lg" />
          )}
        </div>
        <p translate="no">{userInfo?.first_name ?? "Profile"}</p>
      </button>
      <div
        ref={menuRef}
        className={`absolute ${
          open ? "block" : "hidden"
        } w-[232px] right-0 bg-white top-12 z-50 customshadow p-2 transition-all rounded`}
      >
        <div className="absolute right-3 -top-3 h-6 w-6 bg-white border-l border-t rotate-45"></div>
        <div className="flex flex-col px-3 relative font-medium">
          {links.map((item, i) => (
            <Link
              to={item.url}
              className="border-b py-3 px-2 text-base text-neutral-700 hover:text-neutral-800"
              key={i}
              translate="no"
            >
              {item.title}
            </Link>
          ))}
          <button
            translate="no"
            onClick={handleLogout}
            className="py-3 px-2 text-base text-neutral-700 hover:text-neutral-800 text-start"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
