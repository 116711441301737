import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { color } from "framer-motion";

const BASE_URL = process.env.REACT_APP_API_URL;

const Hero = () => {
  const [banners, setBanners] = useState([]);
  const [advertise, setAdvertise] = useState([]);
  const [loading, setLoading] = useState(true);

  const getHeroBanners = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/method/keno_store.api.get_slideshow?slideshow_name=Hero`
    );
    setBanners(data?.message?.slides);
    setLoading(false);
  };

  const getAdvertise = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/method/keno_store.api.get_slideshow?slideshow_name=Advertise`
    );
    setAdvertise(data?.message?.slides);
    setLoading(false);
  };

  useEffect(() => {
    getHeroBanners();
    getAdvertise();
  }, []);

  return (
    <div className="container">
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay, Navigation, Pagination]}
        className="mySwiper py-10"
      >
        <SwiperSlide>
          <div className="h-[350px] relative overflow-hidden rounded-xl">
            <div className="max-w-[780px] herobg absolute right-0 h-[350px] w-full scale-x-[-1]"></div>
            <div className="w-full relative h-full md:bg-gradient-to-r bg-gradient-to-b from-[#f1eaff] via-[#f1eaffe3] to-transparent flex items-center justify-between">
              <div className="flex md:justify-center gap-3 py-[20px] md:py-[40px] px-[10px] flex-col w-full h-full ml-5 md:ml-10">
                <h3 className="text-[32px] md:text-5xl font-semibold">
                  Your <span className="text-ourPrimary">One-Stop</span> <span style={{ color: "#00a650" }}>Halal</span><br />{" "}
                  Grocery Destination
                </h3>
                <p className="text-base font-normal text-black text-wrap">
                  Everything you need, from pantry staples to gourmet treats.
                </p>
                <div className="flex md:mt-10 mt-5">
                  <Link
                    to={"/products"}
                    className="px-10 py-4 rounded-full bg-ourPrimary text-white flex items-center gap-2 hover:bg-ourPrimary-foreground transition-all"
                  >
                    Shop Now <BsArrowRight />
                  </Link>
                </div>
              </div>
              <div className="relative h-full w-full hidden md:flex items-center justify-center">
                <img src="/assets/surface1.png" alt="surface" />
              </div>
            </div>
          </div>
        </SwiperSlide>
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <div className="h-[350px] relative overflow-hidden rounded-xl">
              <div
                className="max-w-[780px] bg-cover bg-no-repeat absolute right-0 h-[350px] w-full"
                style={{ backgroundImage: `url(${BASE_URL}${banner.image})` }}
              ></div>
              <div className="w-full relative h-full md:bg-gradient-to-r bg-gradient-to-b from-[#f1eaff] via-[#f1eaffe3] to-transparent flex items-center justify-between">
                <div className="flex md:justify-center gap-3 py-[20px] md:py-[40px] px-[10px] flex-col w-full h-full ml-5 md:ml-10">
                  <h3 className="text-[32px] md:text-5xl font-semibold">
                    {banner.caption}
                  </h3>
                  <p className="text-base font-normal text-black text-wrap">
                    {banner.description}
                  </p>
                  <div className="flex-grow flex items-center">
                    <div className="flex md:my-10 my-5">
                      <Link
                        to={banner.url}
                        className="px-10 py-4 rounded-full bg-ourPrimary text-white flex items-center gap-2 hover:bg-ourPrimary-foreground transition-all"
                      >
                        Shop Now <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="relative h-full w-full hidden md:flex items-center justify-center">
                  {/* <img src="/assets/surface1.png" alt="surface" /> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* special offers */}
      <div className="mt-5 grid grid-cols-1 md:grid-cols-3 gap-5">
        {loading &&
          [1, 2, 3].map((item, i) => (
            <div className="h-[300px] bg-slate-300 animate-pulse rounded-lg"></div>
          ))}
        {advertise?.map((item, i) => (
          <div
            className={`bg-[#ebe2fd] rounded-lg p-7 ${
              i === 1 && "bg-[#fdecf7]"
            } ${i === 2 && "bg-[#fff8eb]"}`}
            key={i}
          >
            <Link to={item.url}><h3 className="text-3xl font-medium">{item.description}</h3>
            <div className="flex items-center justify-center h-full">
              <img
                src={`${BASE_URL}${item.image}`}
                alt="Grocery"
                className="w-[300px]"
              />
            </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
