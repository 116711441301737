import { Calendar, HomeIcon } from "lucide-react";
import React from "react";
import { FaAngleRight, FaRegCommentAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const SingleBlogPage = () => {
  const post = {
    date: "Nov 26, 2023",
    title: "A Woman tosses fertilizer on her rice crop",
    comments: 65,
    imageUrl: "https://via.placeholder.com/800",
    description:
      "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
    content: `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vehicula euismod est, in tincidunt nulla. Curabitur tincidunt, erat at vehicula imperdiet, purus urna dignissim lorem, vel blandit nulla sem in metus. Praesent interdum vulputate felis. Ut at diam a quam gravida bibendum non ut purus. Proin auctor, velit ac vehicula sagittis, sapien orci sollicitudin quam, ut dictum dui lorem in eros.
      
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean condimentum ligula at eros porttitor, et gravida massa ultricies. Mauris ac tortor quam. Vivamus ut justo vitae dui laoreet eleifend non non metus. In hac habitasse platea dictumst. Suspendisse potenti. Morbi id lectus non metus gravida tincidunt.
      
      Donec accumsan magna ut massa ultricies, quis ullamcorper justo consectetur. In vitae purus non orci scelerisque porttitor id nec odio. Cras eget sapien in velit cursus luctus et at purus. Ut in ante nec libero ullamcorper dapibus. Vivamus auctor nulla sit amet ligula laoreet fermentum. Praesent ultricies enim ac metus sollicitudin, at dapibus nisi convallis.
    `,
  };

  const recentPosts = [
    {
      id: 1,
      date: "May 25, 2024",
      title: "Agriculture Provides most of the world’s food",
      imageUrl: "https://via.placeholder.com/100",
    },
    {
      id: 2,
      date: "June 15, 2024",
      title: "Agriculture also provides wood for constructions",
      imageUrl: "https://via.placeholder.com/100",
    },
  ];

  return (
    <div>
      <div className="relative overflow-hidden -mt-1 bg-black">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 p-3 text-white relative container font-light">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Blogs</p>
            <FaAngleRight />
            <p className="text-yellow-500">Title</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2">
            <div className="bg-white rounded-md overflow-hidden">
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-64 object-cover"
              />
              <div className="py-5">
                <div className="text-sm font-medium flex items-center justify-between">
                  <span>{post.date}</span>
                  <div className="ml-1 flex items-center gap-1">
                    <FaRegCommentAlt className="text-yellow-400" />
                    {post.comments} Comments
                  </div>
                </div>
                <h2 className="text-xl font-semibold mt-4">{post.title}</h2>
                <p className="text-gray-700 my-6">{post.description}</p>
                <div className="prose prose-lg max-w-none">
                  {post.content.split("\n").map((paragraph, idx) => (
                    <p key={idx}>{paragraph}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-white rounded-md overflow-hidden py-3">
              <h3 className="text-xl font-semibold mb-4 ">Recent posts</h3>
              {recentPosts.map((post) => (
                <div
                  key={post.id}
                  className="flex items-center mb-4 border-t py-3 gap-3 "
                >
                  <img
                    src={post.imageUrl}
                    alt={post.title}
                    className="w-16 h-16 object-cover rounded-md mr-4"
                  />
                  <div className="flex flex-col gap-2">
                    <div className="text-gray-600 text-sm flex items-center gap-2">
                      <span>
                        <Calendar size={15} />
                      </span>
                      {post.date}
                    </div>
                    <h4 className="text-md font-semibold">{post.title}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlogPage;
