import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaTrash, FaXmark } from "react-icons/fa6";
import { IoBagHandleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  generateSessionId,
  get_cart_quotation,
  updateGuestUserCart,
  updateRegisteredUserCart,
} from "../../../store/reducers/CartReducer";
import { messageClear } from "../../../store/reducers/HomeReducer";
import { Loader } from "lucide-react";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_API_URL;

const Cart = ({ isMobile }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const userToken = localStorage.getItem("userToken");
  const [cartLegnth, setCartLength] = useState(0);
  const { cartItems, successMessage, loading, errorMessage } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(
    localStorage.getItem("session_id")
  );

  useEffect(() => {
    const token = localStorage.getItem("session_id");
    setSessionId(token);
  });
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  useEffect(() => {
    dispatch(get_cart_quotation({ sessionId }));
  }, [sessionId, dispatch]);

  useEffect(() => {
    if (successMessage) {
      dispatch(messageClear());
      dispatch(get_cart_quotation({ sessionId }));
      window.dispatchEvent(new Event("storage"));
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage, dispatch, sessionId]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (cartItems?.cart?.items?.length > 0) {
      localStorage.setItem("cart_length", cartItems?.cart?.items?.length);
      setIsLoading(false);
    } else {
      if (!isLoading) {
        localStorage.setItem("cart_length", 0);
      }
    }
    const storedLength = localStorage.getItem("cart_length");
    if (storedLength) {
      setCartLength(storedLength);
    } else {
      setCartLength(0);
    }
  }, [cartItems?.cart?.items?.length]);

  // const updateCart = async (itemCode, quantity) => {
  //   if (userToken) {
  //     await dispatch(
  //       updateRegisteredUserCart({
  //         itemCode,
  //         qty: quantity,
  //         withItems: 0,
  //       })
  //     );
  //   } else {
  //     if (!sessionId) {
  //       await dispatch(generateSessionId());
  //     }
  //     await dispatch(
  //       updateGuestUserCart({
  //         itemCode,
  //         qty: quantity,
  //         withItems: 0,
  //         sessionId,
  //       })
  //     );
  //   }
  //   dispatch(get_cart_quotation({ sessionId }));
  // };

  const updateCart = async (itemCode, quantity) => {
    try {
      let response;
  
      if (userToken) {
        response = await dispatch(
          updateRegisteredUserCart({
            itemCode,
            qty: quantity,
            withItems: 0,
          })
        );
        
        if (response?.error) {
          // Extracting the message from the error object
          const errorMessage = response.payload.data.error || "An error occurred while updating the cart.";
          toast.error(errorMessage);
          return;
        }
      } else {
        if (!sessionId) {
          const sessionResponse = await dispatch(generateSessionId());
          if (sessionResponse?.error) {
            const errorMessage = sessionResponse.error.message || "Failed to generate session ID.";
            toast.error(errorMessage);
            return;
          }
          sessionId = sessionResponse?.sessionId;
        }
  
        response = await dispatch(
          updateGuestUserCart({
            itemCode,
            qty: quantity,
            withItems: 0,
            sessionId,
          })
        );
        if (response?.error) {
          const errorMessage = response.payload.data.error || "An error occurred while updating the cart.";
          toast.error(`${errorMessage}`);
          return;
        }
      }
  
      const cartResponse = await dispatch(get_cart_quotation({ sessionId }));
      if (cartResponse?.error) {
        const errorMessage = cartResponse.error.message || "Failed to fetch updated cart.";
        toast.error(errorMessage);
      }
      // } else {
      //   toast.success("Cart updated successfully!");
      // }
    } catch (error) {
      toast.error("An unexpected error occurred while updating the cart.");
      console.error("Unexpected error updating cart:", error);
    }
  };      

  const handleIncrease = (code, quantity) => {
    updateCart(code, quantity + 1);
  };

  const handleDecrease = (code, quantity) => {
    if (quantity > 1) {
      updateCart(code, quantity - 1);
    } else {
      handleRemove(code);
    }
  };

  const handleRemove = async (code) => {
    if (userToken) {
      await dispatch(
        updateRegisteredUserCart({
          itemCode: code,
          qty: 0,
          withItems: 0,
        })
      );
    } else {
      if (!sessionId) {
        await dispatch(generateSessionId());
      }
      await dispatch(
        updateGuestUserCart({
          itemCode: code,
          qty: 0,
          withItems: 0,
          sessionId,
        })
      );
    }
    dispatch(get_cart_quotation({ sessionId }));
  };

  const totalAmount = cartItems?.cart?.items
    ?.filter((item) => item.amount)
    ?.reduce((acc, item) => acc + item.amount, 0);

  return (
    <div className={`${!isMobile && "w-full relative"}`}>
      <div
        onClick={() => setOpen(true)}
        className="flex items-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer justify-center"
      >
        <div className="relative">
          <div
            className={`absolute bg-ourPrimary h-4 w-4 rounded-full flex items-center justify-center text-[10px] text-white -right-2 -top-2`}
          >
            {cartLegnth || 0}
          </div>
          <IoBagHandleOutline
            className={`${isMobile ? "text-2xl" : "text-lg"}`}
          />
        </div>
        <p className={`${isMobile && "hidden"} max-w-[70px] truncate`}>Cart</p>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            maxHeight: "800px",
            height: "auto",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <div className="p-4 bg-white relative w-full overflow-auto overflow-x-hidden hidescroll">
          {loading && (
            <div className="absolute h-full w-full flex items-center justify-center z-20">
              <Loader className="size-10 animate-spin" />
            </div>
          )}
          <div className="flex items-center justify-between" translate="no">
            <h3 className="text-lg font-semibold">
              Shopping Cart ({cartLegnth})
              {/* Shopping Cart ({cartItems?.cart?.items?.length ?? 0}) */}
            </h3>
            <button onClick={() => setOpen(false)}>
              <FaXmark />
            </button>
          </div>

          <div className="mt-5 w-full noscrollbar flex flex-col gap-2">
            {cartItems?.cart?.items?.map((item, i) => (
              <div
                key={i}
                className="border p-3 rounded-lg grid grid-cols-3 md:flex items-center justify-between px-2"
              >
                <div className="">
                  <img
                    src={`${BASE_URL}${item.image}`}
                    alt={item.item_name}
                    className="w-[80px]"
                  />
                </div>
                <Link
                  to={`/product/${item.item_code}`}
                  className="max-w-44 w-full flex-grow"
                >
                  <h3 className="truncate text-lg font-medium hover:underline flex items-center">
                    {item.item_name}
                  </h3>
                  <div className="flex items-center gap-2">
                    <p className="font-semibold text-neutral-500">${item.price}</p>
                    {item.base_price !== item.price && (
                      <p className="text-xs text-neutral-400 line-through">
                        ${item.base_price}
                      </p>
                    )}
                  </div>
                </Link>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-end">
                    <FaTrash
                      className="cursor-pointer"
                      onClick={() => handleRemove(item.item_code)}
                    />
                  </div>
                  <div className="flex items-end justify-end flex-col">
                    <h4 className="font-semibold">${item.amount}</h4>
                  </div>
                  <div className="flex items-center justify-center rounded-full bg-neutral-100">
                    <button
                      onClick={() =>
                        handleDecrease(item.item_code, item.quantity)
                      }
                      className="px-3 text-xl"
                    >
                      -
                    </button>
                    <div className="h-8 w-12 border flex items-center justify-center">
                      {item.quantity}
                    </div>
                    <button
                      onClick={() =>
                        handleIncrease(item.item_code, item.quantity)
                      }
                      className="px-3 text-xl"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {cartItems?.cart?.items?.length === 0 && (
              <h3>No Products added!</h3>
            )}
          </div>

          <div className="mt-10 p-3 px-5">
            <div>
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium">
                  {cartItems?.cart?.items?.length ?? 0} Products
                </h3>
                <p className="font-bold">
                  ${parseFloat(totalAmount?.toFixed(2)) || 0}
                </p>
              </div>

              <div className="flex items-center justify-center flex-col gap-3 mt-5">
                <Link
                  to={cartItems?.cart?.items?.length !== 0 ? "/checkout" : "#"}
                  className="px-5 py-3 rounded-full bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all w-full text-lg font-semibold text-center"
                >
                  Checkout
                </Link>
                <Link
                  to={"/products/"}
                  className="px-5 py-3 rounded-full border border-neutral-300 hover:bg-ourPrimary hover:text-white transition-all w-full text-lg font-semibold text-center"
                >
                  Go To Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Cart;
