import React, { useEffect } from "react";
import { RadioGroup, RadioGroupItem } from "../../components/ui/RadioGroup";
import StarRatings from "react-star-ratings";
import { useDispatch, useSelector } from "react-redux";
import { get_category } from "../../store/reducers/HomeReducer";

const Filter = ({
  selectedCategory,
  setSelectedCategory,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  rate,
  setRate,
  handleApply,
}) => {
  const { categories } = useSelector((state) => state.home);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_category());
  }, [dispatch]);

  return (
    <div className="max-w-[250px] w-full border-r p-3">
      <div>
        <h2 className="text-xl text-neutral-700 font-semibold">Categories:</h2>
      </div>
      <div className="mt-3">
        <RadioGroup
          value={selectedCategory}
          onValueChange={setSelectedCategory}
        >
          {categories.map((item, i) => (
            <div key={i} className="flex items-center space-x-4">
              <RadioGroupItem value={item.name} id={item.name}></RadioGroupItem>
              <label
                htmlFor={item.name}
                className="ml-2 text-neutral-500 cursor-pointer"
              >
                {item.name}
              </label>
            </div>
          ))}
        </RadioGroup>
      </div>

      <div className="mt-5">
        <h2 className="text-xl text-neutral-700 font-semibold">By Price:</h2>

        <div className="mt-3 flex items-center justify-between gap-2">
          <input
            type="number"
            min={0}
            placeholder="Min"
            className="px-3 py-2 bg-transparent rounded-full border min-w-0 text-center"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
          />{" "}
          -
          <input
            type="number"
            min={0}
            placeholder="Max"
            className="px-3 py-2 bg-transparent rounded-full border min-w-0 text-center"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-center my-2">
          <button
            onClick={handleApply}
            className="px-5 py-2 rounded-full bg-ourPrimary hover:bg-ourPrimary-foreground transition-all text-white"
          >
            Apply
          </button>
        </div>
      </div>

      <div className="mt-5">
        <h2 className="text-xl text-neutral-700 font-semibold">By Rate:</h2>
        <div className="flex items-center justify-center mt-2 mb-5">
          <StarRatings
            starDimension="30px"
            starSpacing="2px"
            starRatedColor="#07703c"
            rating={rate}
            changeRating={setRate}
            starHoverColor="#cb6ce6"
          />
        </div>
        {/* <div className="flex items-center justify-center my-2">
          <button
            onClick={handleApply}
            className="px-5 py-2 rounded-full bg-ourPrimary hover:bg-ourPrimary-foreground transition-all text-white"
          >
            Apply
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Filter;
