import { ArrowRight, MoveRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const ShopAndEarn = () => {
  return (
    <div className="container p-3 my-5">
      <div className="flex items-center justify-between">
        <h2 className="text-lg md:text-2xl font-semibold">Shop and Earn</h2>
        <div>
          <Link
            to={"/products"}
            className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
          >
            View All <MoveRight />
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 bg-neutral-950 text-white rounded-xl overflow-hidden mt-5">
        <div className="flex md:hidden items-center justify-center overflow-hidden max-h-[250px]">
          <img src="/assets/coin.jpeg" alt="Coin" />
        </div>
        <div className="flex items-center justify-center text-center flex-col gap-4 py-10">
          <div className="text-3xl font-semibold">
            Buy More And <br /> Earn Point
          </div>
          <p className="text-neutral-300">
            Use your points to purchase products.
          </p>
          <Link
            to={"/shopandearn"}
            className="px-5 py-2 rounded-full bg-white text-black font-bold flex items-center gap-2 hover:bg-neutral-200 transition-all"
          >
            Learn More <ArrowRight />
          </Link>
        </div>
        <div className="max-h-[310px] hidden md:flex items-center justify-center overflow-hidden">
          <img src="/assets/coin.jpeg" alt="Coin" />
        </div>
      </div>
    </div>
  );
};

export default ShopAndEarn;
