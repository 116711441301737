import React, { useEffect, useState } from "react";
import { FaAngleRight, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  generateSessionId,
  get_cart_quotation,
  updateGuestUserCart,
  updateRegisteredUserCart,
} from "../../store/reducers/CartReducer";
import { messageClear } from "../../store/reducers/HomeReducer";
import { HomeIcon, Loader } from "lucide-react";
import toast from "react-hot-toast";
import AccountNav from "../../components/Shared/AccountNav/AccountNav";
import NavigationMenu from "./NavigationMenu";

const BASE_URL = process.env.REACT_APP_API_URL;

const MyCart = () => {
  const userToken = localStorage.getItem("userToken");
  const { cartItems, successMessage, loading, errorMessage } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(
    localStorage.getItem("session_id")
  );

  useEffect(() => {
    dispatch(get_cart_quotation({ sessionId }));
  }, [sessionId, dispatch]);

  useEffect(() => {
    if (successMessage) {
      dispatch(messageClear());
      dispatch(get_cart_quotation({ sessionId }));
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage, dispatch, sessionId]);

  const updateCart = async (itemCode, quantity) => {
    if (userToken) {
      await dispatch(
        updateRegisteredUserCart({
          itemCode,
          qty: quantity,
          withItems: 0,
        })
      );
    } else {
      if (!sessionId) {
        await dispatch(generateSessionId());
      }
      await dispatch(
        updateGuestUserCart({
          itemCode,
          qty: quantity,
          withItems: 0,
          sessionId,
        })
      );
    }
    dispatch(get_cart_quotation({ sessionId }));
  };

  const handleIncrease = (code, quantity) => {
    updateCart(code, quantity + 1);
  };

  const handleDecrease = (code, quantity) => {
    if (quantity > 1) {
      updateCart(code, quantity - 1);
    } else {
      handleRemove(code);
    }
  };

  const handleRemove = async (code) => {
    if (userToken) {
      await dispatch(
        updateRegisteredUserCart({
          itemCode: code,
          qty: 0,
          withItems: 0,
        })
      );
    } else {
      if (!sessionId) {
        await dispatch(generateSessionId());
      }
      await dispatch(
        updateGuestUserCart({
          itemCode: code,
          qty: 0,
          withItems: 0,
          sessionId,
        })
      );
    }
    dispatch(get_cart_quotation({ sessionId }));
  };

  const totalAmount = cartItems?.cart?.items
    ?.filter((item) => item.amount)
    ?.reduce((acc, item) => acc + item.amount, 0);

  return (
    <div>
      <div className="relative overflow-hidden -mt-1 bg-black">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 text-white relative container font-light px-3">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Account</p>
            <FaAngleRight />
            <p className="text-yellow-500">My Cart</p>
          </div>
        </div>
      </div>

      <div className="container flex items-start gap-10 h-full w-full bg-white md:px-8">
        <div className="hidden lg:block max-w-[300px] w-full">
          <AccountNav />
        </div>

        <div className="rounded-lg w-full my-5">
          <div className="p-4 block md:hidden sticky top-0 bg-white z-20">
            <div className="border px-2 py-3 rounded-lg flex items-center justify-between">
              <h3 className="font-semibold">Manage My Account</h3>
              <NavigationMenu />
            </div>
          </div>

          {/* cart */}
          <div className="bg-white relative p-3 w-full overflow-hidden">
            {loading && (
              <div className="absolute h-full w-full flex items-center justify-center z-20">
                <Loader className="size-10 animate-spin" />
              </div>
            )}
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">
                Shopping Cart ({cartItems?.cart?.items?.length ?? 0})
              </h3>
            </div>

            <div className="mt-5 w-full noscrollbar flex flex-col gap-2">
              {cartItems?.cart?.items?.map((item, i) => (
                <div
                  key={i}
                  className="border p-3 rounded-lg grid grid-cols-3 md:flex items-center justify-between px-2"
                >
                  <div className="">
                    <img
                      src={`${BASE_URL}${item.image}`}
                      alt={item.item_name}
                      className="w-[80px]"
                    />
                  </div>
                  <Link
                    to={`/product/${item.item_code}`}
                    className="max-w-44 w-full flex-grow"
                  >
                    <h3 className="text-wrap text-lg font-medium hover:underline">
                      {item.item_name}
                    </h3>
                    <div className="flex items-center gap-2">
                      <p className="font-semibold text-neutral-500">
                        ${item.price}
                      </p>
                      {item.base_price !== item.price && (
                        <p className="text-xs text-neutral-400 line-through">
                          ${item.base_price}
                        </p>
                      )}
                    </div>
                  </Link>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-end">
                      <FaTrash
                        className="cursor-pointer"
                        onClick={() => handleRemove(item.item_code)}
                      />
                    </div>
                    <div className="flex items-end justify-end flex-col">
                      <h4 className="font-semibold">${item.amount}</h4>
                    </div>
                    <div className="flex items-center justify-center rounded-full bg-neutral-100">
                      <button
                        onClick={() =>
                          handleDecrease(item.item_code, item.quantity)
                        }
                        className="px-3 text-xl"
                      >
                        -
                      </button>
                      <div className="h-8 w-12 border flex items-center justify-center">
                        {item.quantity}
                      </div>
                      <button
                        onClick={() =>
                          handleIncrease(item.item_code, item.quantity)
                        }
                        className="px-3 text-xl"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {cartItems?.cart?.items?.length === 0 && (
                <h3>No Products added!</h3>
              )}
            </div>

            <div className="mt-10 p-3 px-5">
              <div>
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium">
                    {cartItems?.cart?.items?.length ?? 0} Products
                  </h3>
                  <p className="font-bold">
                    ${parseFloat(totalAmount?.toFixed(2)) || 0}
                  </p>
                </div>

                <div className="flex items-center justify-center flex-col gap-3 mt-5">
                  <Link
                    to={cartItems?.cart.items?.length !== 0 ? "/checkout" : "#"}
                    className="px-5 py-3 rounded-full bg-ourPrimary text-white hover:bg-ourPrimary-foreground transition-all w-full text-lg font-semibold text-center"
                  >
                    Checkout
                  </Link>
                  <Link
                    to={"/products/"}
                    className="px-5 py-3 rounded-full border border-neutral-300 hover:bg-ourPrimary hover:text-white transition-all w-full text-lg font-semibold text-center"
                  >
                    Go To Shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
