import React, { useEffect, useState } from "react";
import AccountNav from "../../components/Shared/AccountNav/AccountNav";
import NavigationMenu from "./NavigationMenu";
import { HomeIcon, Loader2 } from "lucide-react";
import { FaAngleRight, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessages,
  getWishlist,
  removeFromWishlist,
  updateRegisteredUserCart,
} from "../../store/reducers/CartReducer";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_API_URL


const Wishlist = () => {
  const { wishListItems, loading, successMessage, errorMessage } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();

  const [currentId, setCurrentId] = useState("");

  useEffect(() => {
    if (successMessage && successMessage !== "Product added to Wishlist!") {
      toast.success(successMessage);
      setCurrentId("");
      dispatch(getWishlist());
      dispatch(clearMessages());
    } else if (errorMessage) {
      toast.error(errorMessage);
      setCurrentId("");
      dispatch(clearMessages());
    }
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    dispatch(getWishlist());
    dispatch(clearMessages());
  }, []);

  const handleAddToCart = (code) => {
    setCurrentId(code);
    dispatch(
      updateRegisteredUserCart({
        itemCode: code,
        qty: 1,
      })
    );
  };
  const handleRemove = (code) => {
    dispatch(
      removeFromWishlist({
        itemCode: code
      })
    );
  };

  return (
    <div>
      <div className="relative overflow-hidden -mt-1 bg-black">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 text-white relative container font-light px-3">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Account</p>
            <FaAngleRight />
            <p className="text-yellow-500">My Wishlists</p>
          </div>
        </div>
      </div>

      <div className="container flex items-start gap-10 h-full w-full bg-white md:px-8">
        <div className="hidden lg:block max-w-[300px] w-full">
          <AccountNav />
        </div>

        <div className="rounded-lg w-full my-5">
          <div className="p-4 block md:hidden sticky top-0 bg-white z-20">
            <div className="border px-2 py-3 rounded-lg flex items-center justify-between">
              <h3 className="font-semibold">Manage My Account</h3>
              <NavigationMenu />
            </div>
          </div>

          {/* Lists */}
          <div className="mt-6 flex flex-col px-3 gap-5">
            <div>
              <h3 className="text-xl font-medium">My Wishlists:</h3>
            </div>

            <div className="flex flex-col gap-3">
              {wishListItems?.map((product, i) => (
                <div key={i} className="w-full border rounded">
                  <div className="flex items-center flex-col md:flex-row">
                    <div className="flex items-center w-full">
                      <div>
                        <img
                          src={`${BASE_URL}${product.image}`}
                          alt={product.item_name}
                          className="w-[200px]"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row items-center justify-between w-full h-full px-5 py-3">
                        <Link
                          to={`/product/${product.item_code}`}
                          className="flex flex-col gap-1 group"
                        >
                          <h3 className="text-xl font-bold text-neutral-700 group-hover:underline">
                            {product.item_name}
                          </h3>
                          <p className="text-neutral-500 font-semibold">
                            {"product.weight"}
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="flex flex-col items-start md:items-center justify-between h-full gap-5 px-4 w-full md:w-auto my-5">
                      <h4 className="text-xl font-semibold text-neutral-700">
                        {product.formatted_price}
                      </h4>
                      <div className="grid grid-cols-2 place-items-center gap-4 w-full">
                        <button
                          onClick={() => handleAddToCart(product.item_code)}
                          className="px-5 py-2 rounded-lg border border-green-700 font-semibold text-green-700 hover:bg-green-700 hover:text-white transition-all flex text-nowrap w-[130px]"
                        >
                          {loading && currentId === product.item_code ? (
                            <Loader2
                              size={24}
                              className="animate-spin mx-auto"
                            />
                          ) : (
                            "Add to Cart"
                          )}
                        </button>
                        <FaTrash
                          onClick={()=> handleRemove(product.item_code)}
                          className="text-red-500 cursor-pointer size-5"
                        />
                      </div>
                    </div>
                  </div>
                  <Link
                    to={`/product/${product.item_code}`}
                    className="w-fill flex items-center justify-between py-3 px-8 border-t text-neutral-500 hover:text-neutral-700 transition-all font-semibold"
                  >
                    <div>
                      <h3>
                        <span className="font-bold">View Details</span>{" "}
                        {/* <span>(Order #{order.id})</span> */}
                      </h3>
                    </div>
                    <div>
                      <FaAngleRight className="text-xl text-green-700" />
                    </div>
                  </Link>
                </div>
              ))}
              {wishListItems.length === 0 && !loading && (
                <h3 className="my-4 font-semibold">No Products to show!</h3>
              )}
              {wishListItems.length === 0 && loading && (
                <div className="h-[150px] w-full bg-slate-200 animate-pulse rounded-lg"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
