import React, { useEffect, useRef, useState } from "react";
// import Drawer from "@mui/material/Drawer";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FaGlobe, FaBars, FaXmark } from "react-icons/fa6";
import { FaRegHeart, FaRegUserCircle } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { AiFillHome } from "react-icons/ai";
import Cart from "./Cart";
import NavMenu from "./NavMenu";
import GoogleTranslate from "../../GoogleTranslate";

const NavBar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [scrollValue, setScrollValue] = useState(0);
  const [loggedin, setLoggedin] = useState(localStorage.getItem("userId"));

  const [searchParams] = useSearchParams();

  const specialCategory = searchParams.get("specialCategory");

  const navigate = useNavigate();

  const SearchInput = useRef(null);

  const getScroll = () => {
    const value = window.scrollY;
    setScrollValue(value);
  };

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId) {
      setLoggedin(true);
    } else {
      setLoggedin(false);
    }
  }, [userId]);

  useEffect(() => {
    window.addEventListener("scroll", getScroll);

    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  const links = [
    {
      title: "Hot Deals",
      link: "/products?specialCategory=Hot Deals",
    },
    {
      title: "Top Selling",
      link: "/products?specialCategory=Top Selling",
    },
    {
      title: "New Products",
      link: "/products?specialCategory=New Products",
    },
    {
      title: "Discount Offers",
      link: "/products?specialCategory=Discount Offers",
    },
    {
      title: "Area Coverage",
      link: "/area-coverage",
    },
  ];

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/products") {
      setSearchValue("");
    }
  }, [location.pathname]);

  //funcions
  const handleFocus = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (SearchInput?.current) {
      SearchInput?.current.focus();
    }
  };

  const handleSearch = () => {
    // TODO: route to search page with search value
    navigate(`/products?search=${searchValue}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for a smooth scroll effect
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="w-full border-b shadow-sm">
      <div className="container px-3">
        <div className="flex items-center justify-between flex-col-reverse md:flex-row">
          <div className="flex items-center justify-between w-full gap-4 md:mr-8">
            <Link to={"/"}>
              <img
                src="/assets/logo.png"
                alt="Logo"
                className="w-[200px] md:w-[100px] py-3"
              />
            </Link>
            <div className="relative max-w-[400px] w-full">
              <input
                type="text"
                className="px-5 py-2 rounded-full outline-none bg-transparent border-[1px] border-ourPrimary w-full pr-10"
                placeholder="Search Beverage"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                ref={SearchInput}
                onKeyDown={handleKeyDown}
              />
              <div
                className="absolute h-8 w-8 rounded-full flex items-center justify-center bg-ourPrimary right-1 top-0 m-[4px] text-white cursor-pointer hover:bg-ourPrimary"
                onClick={handleSearch}
              >
                <FaMagnifyingGlass className="" />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center max-w-[400px] w-full text-xs md:text-sm text-gray-600 pt-5 md:py-[29px]">
            <div className="flex items-center hover:text-ourPrimary transition-all cursor-pointer -mt-[2px] -mr-1 w-full justify-center">
              <GoogleTranslate />
            </div>
            <Link
              to={"/account/mywishlists"}
              className="flex items-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer w-full justify-center"
            >
              <div>
                <FaRegHeart className="text-lg" />
              </div>
              <p className="max-w-[70px] truncate">Wishlist</p>
            </Link>
            <Cart />
            {!loggedin ? (
              <Link
                to={"/signin"}
                className="flex items-center w-full justify-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer"
              >
                <div>
                  <FaRegUserCircle className="text-lg" />
                </div>
                <p className="max-w-[70px] truncate">Sign In</p>
              </Link>
            ) : (
              <NavMenu />
            )}
          </div>
        </div>
      </div>
      {/* header bottom */}
      <div className="p-3 bg-gray-950 text-white">
        <div className="container flex items-center justify-between gap-4 flex-col md:flex-row px-3">
          <div className="flex items-start justify-between sm:justify-start w-full gap-4 md:gap-[38px]">
            <div className="block md:hidden">
              {!navOpen ? (
                <FaBars
                  className="text-xl cursor-pointer hover:text-ourPrimary transition-all -mt-[1px]"
                  onClick={() => setNavOpen(!navOpen)}
                />
              ) : (
                <FaXmark
                  className="text-xl cursor-pointer hover:text-ourPrimary transition-all -mt-[1px]"
                  onClick={() => setNavOpen(!navOpen)}
                />
              )}
            </div>
            <div
              className={`flex items-center gap-[14px] text-xs md:text-sm max-w-full overflow-x-hidden transition-all text-gray-200 mt-[1px] ${
                navOpen && "flex-wrap"
              }`}
            >
              {links.map((link, i) => (
                <Link
                  key={i}
                  to={link.link}
                  className={`hover:text-ourPrimary transition-all text-nowrap text-[11px] ${
                    link.title === specialCategory && "text-ourPrimary"
                  }`}
                >
                  {link.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center flex-nowrap gap-2">
            <img
              src="/assets/surface1.png"
              alt="frame"
              className="mix-blend-difference size-7"
            />
            <p className="text-xs font-extralight text-nowrap">
              One-Stop Halal Grocery Destination
            </p>
          </div>
        </div>
      </div>

      {/* !extra headers */}

      {/* fixed nav */}

      <div
        className={`w-full fixed z-50 left-0 transition-all backdrop-blur-md bg-[#ffffff6c] hidden sm:block ${
          scrollValue < 200 ? "-top-40" : "top-0"
        }`}
      >
        <div className="container px-3">
          <div className="flex items-center justify-between flex-col-reverse md:flex-row">
            <div className="flex items-center justify-between w-full gap-4 md:mr-8">
              <Link to={"/"}>
                <img
                  src="/assets/logo.png"
                  alt="Logo"
                  className="w-[80px] py-3"
                />
              </Link>
              <div className="relative max-w-[400px] w-full">
                <input
                  type="text"
                  className="px-5 py-2 rounded-full outline-none bg-transparent border-[1px] border-ourPrimary w-full pr-10"
                  placeholder="Search Beverage"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <div
                  className="absolute h-8 w-8 rounded-full flex items-center justify-center bg-ourPrimary right-1 top-0 m-[4px] text-white cursor-pointer hover:bg-ourPrimary"
                  onClick={handleSearch}
                >
                  <FaMagnifyingGlass className="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center max-w-[400px] w-full text-xs md:text-sm text-gray-600 py-[15px]">
              <div className="flex items-center hover:text-ourPrimary transition-all cursor-pointer -mt-[2px] -mr-1 w-full justify-center">
                {/* <Select onValueChange={setLanguage} value={language}>
                  <SelectTrigger className="bg-transparent border-none outline-none w-[125px]">
                    <FaGlobe className="text-lg mr-2" />
                    <SelectValue placeholder="English" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="English">English</SelectItem>
                    <SelectItem value="Spanish">Spanish</SelectItem>
                    <SelectItem value="Arabic">Arabic</SelectItem>
                  </SelectContent>
                </Select> */}
                <GoogleTranslate />
              </div>
              <Link
                to={"/account/mywishlists"}
                className="flex items-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer w-full justify-center"
              >
                <div>
                  <FaRegHeart className="text-lg" />
                </div>
                <p className="max-w-[70px] truncate">Wishlist</p>
              </Link>
              <Cart />
              {!loggedin ? (
                <Link
                  to={"/signin"}
                  className="flex items-center w-full justify-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer"
                >
                  <div>
                    <FaRegUserCircle className="text-lg" />
                  </div>
                  <p className="max-w-[70px] truncate">Sign In</p>
                </Link>
              ) : (
                <NavMenu />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* mobile nav */}

      <div className="fixed w-full left-0 bottom-0 bg-white z-50 flex sm:hidden">
        <div className="container">
          <div className="flex items-center justify-between flex-col-reverse md:flex-row">
            <div className="flex items-center justify-end w-full gap-4"></div>
            <div className="flex items-center max-w-[400px] w-full justify-around text-xs md:text-sm text-gray-600 p-3 pt-5 md:pt-3">
              <div
                onClick={handleFocus}
                className="flex items-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer"
              >
                <div>
                  <FaMagnifyingGlass className="text-2xl" />
                </div>
              </div>
              <Link
                to={"/account/mywishlists"}
                className="flex items-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer"
              >
                <div>
                  <FaRegHeart className="text-2xl" />
                </div>
              </Link>
              <Link
                to={"/"}
                className="flex items-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer"
              >
                <div>
                  <AiFillHome className="text-2xl" />
                </div>
              </Link>
              <Cart isMobile={true} />
              <Link
                to={loggedin ? "/account/profile" : "/signin"}
                className="flex items-center gap-1 md:gap-2 hover:text-ourPrimary transition-all cursor-pointer"
              >
                <div>
                  <FaRegUserCircle className="text-2xl" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
