import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import CryptoJS from "crypto-js";

const BASE_URL = process.env.REACT_APP_API_URL

export const customer_register = createAsyncThunk(
  "auth/customer_register",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/method/keno_store.api.signup_customer`,
        info, // This is the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: process.env.API_TOKEN,
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const update_password = createAsyncThunk(
  "auth/update_password",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/api/method/frappe.core.doctype.user.user.update_password`,
        info, // This is the request body
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: process.env.API_TOKEN,
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const customer_login = createAsyncThunk(
  "auth/customer_login",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    // console.log(info);
    const hashedPassword = CryptoJS.AES.encrypt(
      info.pwd,
      "secretkenotoday"
    ).toString();

    try {
      const response = await axios.post(
        `${BASE_URL}/api/method/keno_store.auth_api.custom_login`,

        info,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "token b84a086d15a751a:0067d226332e42a",
          },
          withCredentials: true,
        }
      );

      if (response.data) {
        localStorage.setItem("userId", response.data.sid);
        localStorage.setItem(
          "userInfo",
          JSON.stringify(response.data.user_details)
        );

        localStorage.setItem("userSecret", hashedPassword);
        localStorage.setItem("userToken", response.data.token);
      }
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const AuthReducer = createSlice({
  name: "auth",
  initialState: {
    loader: false,
    errorMessage: "",
    successMessage: "",
    status: "",
    token: localStorage.getItem("customerToken"),
    allOrders: [],
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customer_register.pending, (state) => {
        state.loader = true;
      })
      .addCase(customer_register.rejected, (state, { payload }) => {
        state.errorMessage = payload.message.message;
        state.loader = false;
      })
      .addCase(customer_register.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message.message;
        state.status = payload.message.status;
        state.loader = false;
      })

      .addCase(customer_login.pending, (state) => {
        state.loader = true;
      })
      .addCase(customer_login.rejected, (state, { payload }) => {
        state.errorMessage = payload.response.data.message;
        state.loader = false;
      })
      .addCase(customer_login.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;

        state.loader = false;
      })

      .addCase(update_password.pending, (state) => {
        state.loader = true;
      })
      .addCase(update_password.rejected, (state, { payload }) => {
        state.errorMessage = payload.response.data.message;
        state.loader = false;
      })
      .addCase(update_password.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message;
        state.loader = false;
      })

      
  },
});

export const { messageClear } = AuthReducer.actions;
export default AuthReducer.reducer;
