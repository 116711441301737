import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  get_customer_order_detail,
  messageClear,
  re_order,
} from "../store/reducers/HomeReducer";
import { Loader2 } from "lucide-react";
import toast from "react-hot-toast";

export default function OrderDetailsPage() {
  const { orderid } = useParams();
  const { orderDetails, loading, successMessage, errorMessage } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(get_customer_order_detail({ orderId: orderid }));
  }, [dispatch, orderid]);

  useEffect(()=> {
    if(successMessage){
      toast.success(successMessage)
      dispatch(messageClear())
      navigate('/')
    }
    if(errorMessage){
      toast.error(errorMessage)
      dispatch(messageClear())
    }
  },[successMessage, errorMessage, dispatch, navigate])

  if (loading) {
    return (
      <div className="container mx-auto p-4 text-center h-[40vh] flex items-center justify-center">
        <Loader2 size={30} className="animate-spin" />
      </div>
    );
  }
  console.log(orderDetails);
  

  const handleReorder = async () => {
    dispatch(re_order({ order_id: orderid }));
  };

  return (
    <div className="container mx-auto p-4 max-w-3xl">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Order Details</h1>
      <div className="bg-white border rounded-lg overflow-hidden mb-6">
        <div className="p-6 border-b border-gray-200">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-600">Order ID</p>
              <p className="text-lg font-semibold">{orderDetails.order_id}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Status</p>
              <p className="text-lg font-semibold">
                <span
                  className={`inline-block px-2 py-1 rounded-full text-sm ${
                    orderDetails.status === "Completed"
                      ? "bg-green-100 text-green-800"
                      : orderDetails.status === "Pending"
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-gray-100 text-gray-800"
                  }`}
                >
                  {orderDetails.status}
                </span>
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Date</p>
              <p className="text-lg font-semibold">{orderDetails.date}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Total Amount</p>
              <p className="text-lg font-semibold">
                ${orderDetails.total_amount}
              </p>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-2xl font-bold mb-4 text-gray-800">Order Items</h2>
      <div className="bg-white border rounded-lg overflow-hidden mb-6">
        {orderDetails.items?.map((item, i) => (
          <div
            key={i}
            className={`p-4 ${
              i !== orderDetails.items.length - 1
                ? "border-b border-gray-200"
                : ""
            }`}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {item.item_name}
                </h3>
                <p className="text-sm text-gray-600">
                  Quantity: {item.quantity}
                </p>
              </div>
              <p className="text-lg font-bold text-gray-800">${item.amount}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center">
        <button
          onClick={handleReorder}
          className="bg-ourPrimary hover:bg-ourPrimary-foreground text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
        >
          Reorder
        </button>
      </div>
    </div>
  );
}
