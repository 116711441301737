import { Drawer } from "@mui/material";
import React, { useState } from "react";
import Filter from "./Filter";
import { CiFilter } from "react-icons/ci";

const ProductDrawer = ({
  selectedCategory,
  setSelectedCategory,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  rate,
  setRate,
  handleApply,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button onClick={() => setOpen(true)}>
        <CiFilter className="text-3xl" />
      </button>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Filter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          minPrice={minPrice}
          maxPrice={maxPrice}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          rate={rate}
          setRate={setRate}
          handleApply={handleApply}
        />
      </Drawer>
    </div>
  );
};

export default ProductDrawer;
