import React, { useEffect } from "react";
import { MapPin, Truck, Clock, Loader2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { get_are_coverage } from "../../store/reducers/HomeReducer";

export default function AreaCoverage() {
  const { area_coverage, loading } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_are_coverage());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-50">
        <Loader2 size={48} className="animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-8 text-center">
          Delivery Information
        </h1>

        <div className="bg-white border rounded-lg overflow-hidden mb-8">
          <div className="p-6 sm:p-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-6 flex items-center">
              <MapPin className="mr-3 text-blue-500" size={28} /> Pickup Locations
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {area_coverage?.pickup_locations?.map((location, index) => (
                <div key={index} className="bg-gray-50 p-5 rounded-lg shadow-md hover:shadow-lg transition-all duration-300">
                  <p className="font-semibold text-gray-800 mb-3 text-lg">
                    {location.address}
                  </p>
                  <p className="text-gray-600 flex items-center mb-1">
                    <Clock className="inline-block mr-2 w-5 h-5 text-blue-500" />
                    {location.hours}
                  </p>
                  <p className="text-gray-600 ml-7">{location.days}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white border rounded-lg overflow-hidden transition-all duration-300">
          <div className="p-6 sm:p-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-6 flex items-center">
              <Truck className="mr-3 text-blue-500" size={28} /> Home Delivery
            </h2>

            <div className="mb-8">
              <h3 className="text-2xl font-semibold text-gray-700 mb-4">
                Same Day Delivery
              </h3>
              <div className="overflow-x-auto bg-gray-50 rounded-lg shadow">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Range
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Delivery Charge
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Areas
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {area_coverage?.home_delivery?.same_day_delivery?.map(
                      (item, index) => (
                        <tr key={index} className="hover:bg-gray-50 transition-colors duration-200">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {item.range}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                            {item.delivery_charge}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {item.areas.join(", ")}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-gray-700 mb-4">
                Next Day Delivery
              </h3>
              <div className="overflow-x-auto bg-gray-50 rounded-lg shadow">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Range
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Delivery Charge
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Areas
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {area_coverage?.home_delivery?.next_day_delivery?.map(
                      (item, index) => (
                        <tr key={index} className="hover:bg-gray-50 transition-colors duration-200">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {item.range}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                            {item.delivery_charge}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {item.areas.join(", ")}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}