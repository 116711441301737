import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight, FaRegCommentAlt } from "react-icons/fa";
import { ArrowDown, Calendar, HomeIcon } from "lucide-react";

const Blog = () => {
  const posts = [
    {
      id: 1,
      date: "Nov 26, 2023",
      title: "A Woman tosses ferilizer on her rice crop",
      comments: 65,
      imageUrl: "https://via.placeholder.com/300",
      description:
        "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
    },
    {
      id: 2,
      date: "Nov 26, 2023",
      title: "A Woman tosses ferilizer on her rice crop",
      comments: 65,
      imageUrl: "https://via.placeholder.com/300",
      description:
        "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
    },
    {
      id: 3,
      date: "Nov 26, 2023",
      title: "A Woman tosses ferilizer on her rice crop",
      comments: 65,
      imageUrl: "https://via.placeholder.com/300",
      description:
        "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
    },
    {
      id: 4,
      date: "Nov 26, 2023",
      title: "A Woman tosses ferilizer on her rice crop",
      comments: 65,
      imageUrl: "https://via.placeholder.com/300",
      description:
        "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
    },
  ];

  const recentPosts = [
    {
      id: 1,
      date: "May 25, 2024",
      title: "Agriculture Provides most of the world’s food",
      imageUrl: "https://via.placeholder.com/100",
    },
    {
      id: 2,
      date: "June 15, 2024",
      title: "Agriculture also provides wood for constructions",
      imageUrl: "https://via.placeholder.com/100",
    },
  ];

  //functions
  const handleLoadMore = async (params) => {};

  return (
    <div>
      <div className="relative overflow-hidden -mt-1">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 text-white relative container font-light p-3">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p className="text-yellow-500">Blogs</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {posts.map((post) => (
                <div
                  key={post.id}
                  className="bg-white rounded-md overflow-hidden h-full"
                >
                  <img
                    src={post.imageUrl}
                    alt={post.title}
                    className="w-full h-60 rounded-xl object-cover"
                  />
                  <div className="py-4">
                    <div className="text-sm font-medium flex items-center justify-between">
                      <span>{post.date}</span>
                      <div className="ml-1 flex items-center gap-1">
                        <FaRegCommentAlt className="text-yellow-400" />
                        {post.comments} Comments
                      </div>
                    </div>
                    <h2 className="text-xl font-semibold mt-4">{post.title}</h2>

                    <p className="text-gray-500 mt-4 flex-grow">
                      {post.description}
                    </p>
                    <div className="mt-5">
                      <Link
                        to={`/blog/fsdf`}
                        className=" bg-purple-500 hover:bg-purple-700 transition-all text-white px-4 py-2 rounded-md"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center mt-5">
              <button
                onClick={handleLoadMore}
                className="relative rounded px-5 py-2.5 overflow-hidden group border hover:bg-gradient-to-r hover:from-ourbg-ourPrimary hover:to-ourPrimary-foreground  hover:ring-2 hover:ring-offset-2 hover:ring-outo-ourPrimary-foreground transition-all ease-out duration-300"
              >
                <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                <span className="relative font-medium flex items-center gap-2">
                  Load More <ArrowDown />
                </span>
              </button>
            </div>
          </div>
          <div className="pb-10">
            <div className="bg-white rounded-md overflow-hidden py-4">
              <h3 className="text-xl font-semibold mb-4 ">Recent posts</h3>
              {recentPosts.map((post) => (
                <div
                  key={post.id}
                  className="flex items-center mb-4 border-t py-3 gap-3 "
                >
                  <img
                    src={post.imageUrl}
                    alt={post.title}
                    className="w-16 h-16 object-cover rounded-md mr-4"
                  />
                  <div className="flex flex-col gap-2">
                    <div className="text-gray-600 text-sm flex items-center gap-2">
                      <span>
                        <Calendar size={15} />
                      </span>
                      {post.date}
                    </div>
                    <h4 className="text-md font-semibold">{post.title}</h4>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-[#8776E0] text-white shadow-md max-w-[400px] rounded-md overflow-hidden mt-6 p-4 flex flex-col items-center justify-start relative h-[450px] md:h-[500px] w-auto">
              <div className="absolute h-full min-w-full left-0 top-0">
                <img
                  src="/assets/freshfood.jpeg"
                  alt=""
                  className="absolute left-0 top-0 h-full object-cover w-full"
                />
              </div>
              <div className="text-sm font-semibold bg-purple-600 px-2 py-1 rounded-md inline-block mb-4 relative mt-6">
                NEW
              </div>
              <h3 className="text-3xl  mb-2 relative uppercase">
                Fresh Fruits
              </h3>
              <div className="font-extralight mb-4 relative ">FROM $260/Kg</div>
              <button className=" relative text-white bg-purple-600 hover:bg-purple-700 transition-all rounded-full px-4 py-2">
                Order Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
