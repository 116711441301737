import { Globe2 } from "lucide-react";
import { useEffect, useState, useCallback } from "react";

// Utility function to get a cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

const GoogleTranslate = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [language, setLanguage] = useState("Language");

  // Function to extract and update language from the 'googtrans' cookie
  const updateLanguageFromCookie = useCallback(() => {
    const googtrans = getCookie("googtrans");
    if (googtrans) {
      const lang = googtrans.split("/").pop(); // Extract language code
      setLanguage(lang.toUpperCase()); // Display in uppercase (e.g., "EN", "ES")
    }
  }, []);

  useEffect(() => {
    // Function to add the Google Translate script
    const addGoogleTranslateScript = () => {
      const existingScript = document.querySelector(
        'script[src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
      );
      if (existingScript) {
        setIsScriptLoaded(true);
        return;
      }

      const script = document.createElement("script");
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      script.onload = () => setIsScriptLoaded(true);
      script.onerror = () =>
        console.error("Google Translate script failed to load.");
      document.body.appendChild(script);
    };

    // Initialize the Google Translate element
    window.googleTranslateElementInit = () => {
      try {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,es,ar",
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false,
          },
          "google_translate_element"
        );

        // Hide the Google Translate attribution bar
        const removeGoogleTranslateBar = () => {
          const bar = document.querySelector(".goog-te-banner-frame");
          if (bar) bar.style.display = "none";
        };

        removeGoogleTranslateBar();

        // Add CSS to hide the top bar
        const style = document.createElement("style");
        style.textContent = `
          .goog-te-banner-frame {
            display: none !important;
          }
          .goog-te-menu-value:hover {
            text-decoration: none !important;
          }
          body {
            top: 0 !important;
          }
        `;
        document.head.appendChild(style);
      } catch (error) {
        console.error("Google Translate initialization error:", error);
      }
    };

    // Add the script on component mount
    addGoogleTranslateScript();

    // Initial check for language
    updateLanguageFromCookie();

    // Set an interval to continuously monitor the 'googtrans' cookie
    const intervalId = setInterval(() => {
      updateLanguageFromCookie();
    }, 1000); // Check every 1 second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [updateLanguageFromCookie]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative" onClick={scrollToTop}>
      <div
        id="google_translate_element"
        className={`h-5 absolute left-0 -top-10 ${
          isScriptLoaded ? "opacity-0" : "opacity-0"
        }`}
      ></div>
      <div
        className="flex items-center justify-center gap-1 text-neutral-600"
        translate="no"
      >
        <Globe2 size={18} className="block" />
        {language === "Langulage" ? "English": language === "EN" ? "English" : language === "ES" ? "Spanish": language === "AR" ? "Arabic" : "English"}
      </div>
    </div>
  );
};

export default GoogleTranslate;
