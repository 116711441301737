import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";


import { Navigation, Autoplay } from "swiper/modules";
import ProductCard from "./ProductCard";

const ProductCarousel = ({products}) => {
  
  return (
    <div className="max-w-[1200px] mx-auto">
      <Swiper
        slidesPerView={3} // Default number of slides per view
        spaceBetween={20} // Space between slides
        pagination={{ clickable: true }} // Enable clickable pagination buttons
        autoplay={{
          delay: 2000, // 3 seconds delay
          disableOnInteraction: false, // Continue autoplay after user interactions
        }}
        loop={true}
        modules={[Autoplay, Navigation]} // Include the necessary modules
        className="mySwiper py-10"
        breakpoints={{
          1224: {
            slidesPerView: 3, // 3 slides on large screens
          },
          640: {
            slidesPerView: 2, // 1 slide on small screens
          },
          0: {
            slidesPerView: 2, // 1 slide on small screens
          },
        }}
      >
        {products.map((product, index) => (
          <SwiperSlide key={index}>
            <ProductCard product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductCarousel;
