import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import { FaRegHeart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  clearMessages,
  generateSessionId,
  updateGuestUserCart,
  updateRegisteredUserCart,
  get_cart_quotation,
  addToWishlist,
} from "../store/reducers/CartReducer";
import { Loader } from "lucide-react";

const BASE_URL = process.env.REACT_APP_API_URL;

const ProductCard = ({ product }) => {
  const [userToken] = useState(localStorage.getItem("userSecret"));

  const [loadingProductId, setLoadingProductId] = useState(null);
  const [loadingWishId, setLoadingWishId] = useState(null);
  const [sessionId, setSessionId] = useState(
    localStorage.getItem("session_id")
  );

  const { successMessage, errorMessage, loading } = useSelector(
    (state) => state.cart
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("session_id");
    setSessionId(token);
  });

  useEffect(() => {
    dispatch(clearMessages());
  }, [dispatch]);

  useEffect(() => {
    if (
      successMessage &&
      (loadingProductId || loadingWishId === product?.item_code)
    ) {
      toast.success(successMessage);
      dispatch(clearMessages()); // Clear messages after displaying toast
      setLoadingProductId(null);
      setLoadingWishId(null);

      // Fetch cart items after success
      dispatch(get_cart_quotation({ sessionId }));
    }

    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(clearMessages());
      setLoadingProductId(null);
      setLoadingWishId(null);
    }
  }, [
    successMessage,
    errorMessage,
    dispatch,
    loadingProductId,
    product?.item_code,
    sessionId,
  ]);

  const handleAddToCart = async () => {
    setLoadingProductId(product?.item_code);

    // Determine the quantity to add to the cart
    const qty = product?.minimum_qty && product?.minimum_qty !== 0 ? product?.minimum_qty : 1;

    const addToCart = (sessionId) => {
      const actionPayload = {
        itemCode: product?.item_code,
        qty: qty,
        withItems: 0,
        sessionId,
      };

      if (userToken) {
        dispatch(updateRegisteredUserCart(actionPayload));
      } else {
        dispatch(updateGuestUserCart(actionPayload));
      }
    };

    if (!userToken && !sessionId) {
      const action = dispatch(generateSessionId());
      if (action?.payload?.message) {
        addToCart(sessionId);
      }
    } else {
      addToCart(sessionId);
    }
  };

  const handleWishlist = async () => {
    if (!userToken) {
      navigate("/signin");
    } else {
      setLoadingWishId(product?.item_code);
      dispatch(addToWishlist({ itemCode: product?.item_code }));
    }
  };

  return (
    <div className="relative text-neutral-600 cursor-pointer border border-transparent hover:border-ourPrimary transition-all flex flex-col h-full overflow-x-hidden">
      {product.discount && (
        <div className="flex items-center justify-end absolute z-10 right-0 top-0">
          <div className="px-3 py-1 bg-[#d6ecf5] text-sm font-semibold relative">
            {product.discount}
          </div>
        </div>
      )}
      <Link
        to={`/product/${product?.item_code}`}
        className="relative shadow-md shadow-neutral-400/10 flex items-center justify-center h-[200px] flex-grow overflow-hidden"
      >
        <div className="">
          <img
            src={`${BASE_URL}${product.website_image}`}
            alt={product.title}
            className=""
          />
        </div>
      </Link>
      <div className="p-3 flex flex-col gap-1">
        <Link
          to={`/product/${product?.item_code}`}
          className="font-semibold truncate"
        >
          {product.web_item_name}
        </Link>
        <Link
          to={`/product/${product?.item_code}`}
          className="text-sm text-neutral-500 truncate"
        >
          {product.item_group}
        </Link>
        <Link
          to={`/product/${product?.item_code}`}
          className="mt-[10px] flex items-center justify-between"
        >
          <div className="flex text-2xl font-bold">
            <span>{product.formatted_price}</span>
          </div>
          <div className="flex text-neutral-300">
            <span className="line-through">{product.formatted_mrp}</span>
          </div>
        </Link>
        <Link to={`/product/${product?.item_code}`}>
          <StarRatings
            starDimension="15px"
            starSpacing="2px"
            starRatedColor="#07703c"
            rating={product.rating}
          />
        </Link>
        <div className="flex items-center justify-between mt-[10px] gap-1 -ml-[10px]">
          <button
            onClick={handleWishlist}
            className="border border-ourPrimary rounded-full p-2 font-bold hover:bg-ourPrimary hover:text-white transition-all"
          >
            {loading && setLoadingWishId === product?.item_code ? (
              <Loader size={20} className="animate-spin" />
            ) : (
              <FaRegHeart className="text-lg md:text-xl" />
            )}
          </button>
          <button
            onClick={handleAddToCart}
            className="border border-ourPrimary rounded-full px-2 text-[12px] md:text-sm py-2 font-bold hover:bg-ourPrimary hover:text-white transition-all flex text-nowrap truncate"
            disabled={loading && loadingProductId === product?.item_code}
            // translate="no"
          >
            {loading && loadingProductId === product?.item_code ? (
              <Loader size={20} className="animate-spin" />
            ) : (
              <div className="md:mx-3 truncate">Add To Cart</div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
