import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MoveRight } from "lucide-react";
import ProductSlider from "../../components/ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import SkeletonCarousel from "../../components/SkeletonCarousel";
import { get_dailyproducts } from "../../store/reducers/HomeReducer";

const DailyProducts = () => {
  const { daily_products } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Local loading state

  useEffect(() => {
    const fetchDailyProducts = async () => {
      await dispatch(get_dailyproducts({ page: 1, size: 15 }));
      setLoading(false); // Set loading to false after fetching products
    };

    fetchDailyProducts();
  }, [dispatch]);

  // Do not render anything if products are empty and loading is complete
  if (!loading && (!daily_products || daily_products.length === 0)) {
    return null;
  }

  return (
    <div className="container mx-auto md:py-8 p-3">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg md:text-2xl font-semibold">Local Products</h2>
        <Link
          to={`/products?specialCategory=${encodeURIComponent("Daily Products").trim()}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>
      
      <div>
        {loading ? (
          // Show loading skeleton when loading
          <SkeletonCarousel full={true} />
        ) : (
          <>
            <ProductSlider products={daily_products} />
            {daily_products.length === 0 && (
              <h4 className="my-10 text-lg font-bold py-3">No Products To Show!</h4>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DailyProducts;
