import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MoveRight } from "lucide-react";
import ProductSlider from "../../components/ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import SkeletonCarousel from "../../components/SkeletonCarousel";
import { get_meatandfish } from "../../store/reducers/HomeReducer";

const MeatAndFish = () => {
  const { meatandfish } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Local loading state

  useEffect(() => {
    const fetchMeatAndFish = async () => {
      await dispatch(get_meatandfish({ page: 1, size: 15 }));
      setLoading(false); // Set loading to false after fetching products
    };

    fetchMeatAndFish();
  }, [dispatch]);

  return (
    <div className="container mx-auto p-1">
      <div className="flex justify-between items-center mb-4 px-2">
        <h2 className="text-[20px] md:text-2xl font-semibold">Meat & Fish</h2>
        <Link
          to={`/products?category=${encodeURIComponent("Meat & Fish").trim()}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>
      {loading ? (
        // Show loading skeleton when loading
        <SkeletonCarousel full={true}/>
      ) : meatandfish && meatandfish.length > 0 ? (
        <ProductSlider products={meatandfish} />
      ) : (
        <h4 className="my-10 text-lg font-bold flex items-center justify-center">
          No Products to show!
        </h4>
      )}
    </div>
  );
};

export default MeatAndFish;
