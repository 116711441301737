import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MoveRight } from "lucide-react";
import ProductSlider from "../../components/ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import SkeletonCarousel from "../../components/SkeletonCarousel";
import { get_snacks } from "../../store/reducers/HomeReducer";

const Snacks = () => {
  const { snacks } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Local loading state

  useEffect(() => {
    const fetchSnacks = async () => {
      await dispatch(get_snacks({ page: 1, size: 20 }));
      setLoading(false); // Set loading to false after fetching products
    };

    fetchSnacks();
  }, [dispatch]);

  return (
    <div className="container mx-auto p-1">
      <div className="flex justify-between items-center mb-4 px-2">
        <h2 className="text-[20px] md:text-2xl font-semibold">Snacks</h2>
        <Link
          to={`/products?category=${encodeURIComponent("Snacks").trim()}`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          View All{" "}
          <span className="ml-1">
            <MoveRight />
          </span>
        </Link>
      </div>
      {loading ? (
        // Show loading skeleton when loading
        <SkeletonCarousel full={true}/>
      ) : snacks && snacks.length > 0 ? (
        <ProductSlider products={snacks} />
      ) : (
        <h4 className="my-10 text-lg font-bold flex items-center justify-center">
          No Products to show!
        </h4>
      )}
    </div>
  );
};

export default Snacks;
