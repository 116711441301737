import React, { useEffect, useState } from "react";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { MdOutlineLoop, MdLogout } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { FaBagShopping, FaGear } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get_cart_quotation, removeCartItems } from "../../../store/reducers/CartReducer";

const AccountNav = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [loggedin, setLoggedin] = useState(localStorage.getItem('userId'))
  const dispatch = useDispatch()


  const navigate = useNavigate()

  const handleLogout = async () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userToken')
    localStorage.removeItem('userSecret')
    localStorage.removeItem('cart_length')
    setLoggedin(false)
    dispatch(removeCartItems())
    dispatch(get_cart_quotation())
  };

  const userId = localStorage.getItem('userId')
  
  useEffect(()=>{
    if(userId){
      setLoggedin(true)
    }else{
      setLoggedin(false)
    }
  },[userId])

  useEffect(()=>{
    if(!loggedin){
      navigate('/signin')
    }
  },[loggedin])
  
  const links = [
    {
      title: "Profile",
      url: "/account/profile",
      icon: <BsFillGrid1X2Fill />,
    },
    {
      title: "My Orders",
      url: "/account/myorders",
      icon: <MdOutlineLoop />,
    },
    {
      title: "My Wishlists",
      url: "/account/mywishlists",
      icon: <FaRegHeart />,
    },
    {
      title: "My Cart",
      url: "/account/mycart",
      icon: <FaBagShopping />,
    },
    // {
    //   title: "Setting",
    //   url: "/account/setting",
    //   icon: <FaGear />,
    // },
  ];

  
  return (
    <div className="max-w-[300px] rounded-lg border shadow-sm flex flex-col gap-3 my-3 w-full bg-white">
      <h2 className="text-lg font-semibold px-5 pt-2 mt-2">Manage My Account</h2>
      <div>
        {links.map((item, i) => (
          <Link
            to={item.url}
            key={i}
            className={`flex px-10 py-4 items-center gap-3 font-medium text-neutral-600 hover:bg-ourPrimary/30 hover:text-black ${
              pathname === item.url && "border-r-4 border-r-green-600"
            }`}
          >
            <span className="text-lg">{item.icon}</span>
            <h3>{item.title}</h3>
          </Link>
        ))}
        <button
        onClick={handleLogout}
          className={`flex px-10 py-4 items-center gap-3 font-medium text-neutral-600 hover:bg-ourPrimary/30 hover:text-black w-full `}
        >
          <span className="text-lg">
            <MdLogout />
          </span>
          <h3>Logout</h3>
        </button>
      </div>
    </div>
  );
};

export default AccountNav;
