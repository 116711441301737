import { ArrowRight, MoveRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import Timer from "../../components/Timer";
import { FaDollarSign } from "react-icons/fa6";

const OfferCard = () => {
  return (
    <div className="bg-[#fccc00] p-6 rounded-2xl flex flex-col gap-5 items-center justify-center text-center max-w-[500px] w-full">
      <h2 className="font-semibold">OFFER SALE</h2>
      <h1 className="text-4xl font-bold mt-2">Get All Together</h1>
      <p className="text-lg mt-2">
        Up to{" "}
        <span className="font-bold bg-neutral-900 text-[#fccc00] py-2 px-3 rounded-md">
          64% OFF
        </span>
      </p>
      <Link
        to={"/discountstacking?offer=offersale"}
        className="px-5 py-2 rounded-full bg-white text-black font-semibold flex items-center gap-2 hover:bg-neutral-200 transition-all mt-5"
      >
        Shop Now
        <ArrowRight />
      </Link>
      <img src="/assets/items.png" alt="Offer" className="mt-4 w-[60%]" />
    </div>
  );
};

const DealOfTheWeek = () => {
  return (
    <div className="bg-gray-100 p-4 rounded-2xl flex flex-col items-center text-center max-w-[850px] w-full">
      <div className="flex items-center flex-col md:flex-row justify-between gap-2">
        <div className="flex items-center justify-end w-full md:w-auto">
          <img
            src="/assets/vegitable.png"
            alt="Vegitable"
            className="w-[120px] md:w-[200px] mt-8"
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-sm font-light">BEST DEALS OF THE WEEK</h2>
          <h1 className="text-xl font-bold mt-2">
            Our Special Products Deal of the Month
          </h1>
          <div className=" mt-2">
            <Timer />
          </div>
          <Link
            to={"/discountstacking?offer=bestdeals"}
            className="px-5 py-2 rounded-full border font-semibold flex items-center gap-2 hover:bg-neutral-200 transition-all mt-5 flex-nowrap"
          >
            Shop Now
            <ArrowRight />
          </Link>
        </div>
        <div className="flex items-center justify-start w-full md:w-auto">
          <img
            src="/assets/vegitable2.png"
            alt="Deal of the Week"
            className="w-[120px] md:w-[200px]"
          />
        </div>
      </div>
    </div>
  );
};

const SpiceCard = () => {
  return (
    <div className="bg-black text-white p-4 rounded-2xl flex flex-col items-center text-center relative overflow-hidden">
      <div className="relative flex items-end justify-center h-full z-10 flex-col w-full">
        <h2 className="text-xl font-medium">Natural Spices</h2>
        <p className="mt-2 font-extralight text-sm">
          UP TO <br /> <span className="font-normal text-base">25% OFF</span>
        </p>
        <Link
          to={"/discountstacking?offer=naturalspices"}
          className="px-5 py-2 rounded-full border font-semibold flex items-center gap-2 hover:bg-neutral-200 hover:text-neutral-600 transition-all mt-5"
        >
          Shop Now
          <ArrowRight />
        </Link>
      </div>
      <img
        src="/assets/spices.jpeg"
        alt="Spices"
        className="mt-4 w-full h-auto absolute top-[-50%]"
      />
    </div>
  );
};

const FlashSaleCard = () => {
  return (
    <div className="bg-[#ffdf01] py-2 rounded-2xl flex flex-col items-center text-center">
      <h2 className="text-3xl">FLASH SALE</h2>
      <div className="flex flex-col items-center 2xl:flex-row">
        <img
          src="/assets/vegitable2.png"
          alt="Flash Sales"
          className="w-[120px]"
        />
        <div className="h-full flex flex-col flex-wrap items-center justify-center text-md mt-2">
          <h4>UP TO</h4>
          <div className="flex items-end gap-2 ">
            <p className="text-2xl font-semibold">19%</p>
            <span>Discount</span>
          </div>
          <Link
            to={"/discountstacking?offer=flashsale"}
            className="px-2 py-2 rounded-full border border-black font-semibold flex items-center gap-2 hover:bg-neutral-200 transition-all text-base"
          >
            Shop Now
            <ArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

const BeverageCard = () => {
  return (
    <div className="bg-black text-white p-4 rounded-2xl flex flex-col items-center text-center relative overflow-hidden">
      <div className="relative flex items-end justify-center h-full z-10 flex-col w-full">
        <h2 className="text-2xl font-medium">Beverage Offer&apos;s</h2>
        <p className="mt-2 font-extralight">
          Started At <br />{" "}
          <span className="font-normal text-2xl flex items-center gap-2 text-yellow-400">
            <FaDollarSign />
            1.25
          </span>
        </p>
        {/* <Link
          to={"/discountstacking?offer=bevarageoffer"}
          className="px-5 py-2 rounded-full border font-semibold flex items-center gap-2 hover:bg-neutral-200 hover:text-black transition-all mt-5"
        > */}
        <Link
          to={"/products?category=Soft%20Drinks"}
          className="px-5 py-2 rounded-full border font-semibold flex items-center gap-2 hover:bg-neutral-200 hover:text-black transition-all mt-5"
        >
          Shop Now
          <ArrowRight />
        </Link>
      </div>
      <div className="absolute h-full w-full left-0 top-0 flex items-center justify-center">
        <img
          src="/assets/strawberryjuice.jpeg"
          alt="Spices"
          className="w-full h-auto bottom-[-60%]"
        />
      </div>
    </div>
  );
};

const SummerSaleCard = () => {
  return (
    <div className="bg-gray-100 p-4 rounded-2xl flex flex-col items-center text-center relative overflow-hidden">
      <div className="w-full flex flex-col items-start relative z-10">
        <h3 className="text-lg uppercase border-b border-b-neutral-300">
          Summer Sale
        </h3>
        <div className="mt-5 text-4xl font-semibold">
          <h4>15% OFF</h4>
        </div>
        <p>Only Fruit & Vegetable</p>
        <Link
          to={"/discountstacking?offer=summersale"}
          className="px-5 py-2 rounded-full border border-neutral-500 font-semibold flex items-center gap-2 mt-8 hover:bg-neutral-200 transition-all"
        >
          Shop Now
          <ArrowRight />
        </Link>
      </div>
      <div className="absolute flex w-full h-full items-center justify-center">
        <img
          src="/assets/vegitable3.jpeg"
          alt="Summer Sale"
          className=" scale-x-[-1]"
        />
      </div>
    </div>
  );
};

const DiscountStacking = () => {
  return (
    <div className="p-3 container w-full">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-lg md:text-2xl font-semibold">Discount Stacking</h1>
        <Link
          to={`/discountstacking`}
          className="text-green-600 flex items-center hover:text-green-700 transition-all text-[14px] md:text-[18px] font-normal"
        >
          <span>View All</span> <MoveRight />
        </Link>
      </div>
      <div className="flex justify-center flex-col md:flex-row gap-4">
        <OfferCard />
        <div className="grid grid-cols-1 gap-5">
          <div className="flex flex-col gap-4">
            <DealOfTheWeek />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <SpiceCard />
            <FlashSaleCard />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <BeverageCard />
            <SummerSaleCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountStacking;
